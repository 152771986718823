<template>
  <div>
    <div :id="'googleMap'+mapName"
         ref="googleMapDiv"
         class="google-map"
         :style="{height: height + 'px'}"></div>
  </div>
</template>

<script>
/*
TODO: Move this so that the whole geo object comes in instead of zips and then you have to repeat the lookup
 */
import { Loader } from 'google-maps'
import _throttle from 'lodash.throttle'
import _min from 'lodash.min'
import _max from 'lodash.max'
import _debounce from 'lodash.debounce'

import GOOGLE from 'core-ui/assets/js/constants/google'

const loader = new Loader(GOOGLE.MAPS.API_KEY, { version: 'quarterly', libraries: ['visualization'] })

export default {
  name: 'googleMap',
  props: {
    mapName: {
      type: String,
      default: () => 'GoogleMap_' + Math.round(Math.random() * 1000)
    },
    zips: {},
    excludedZips: {},
    radius: {},
    account: {},
    locations: {},
    height: {
      type: [Number, String],
      default: 260
    },
    hidePin: {
      type: Boolean,
      default: false
    },
    draggableRadius: {
      type: Boolean,
      default: false
    },
    updateRadius: {
      type: Object
    },
    includedRadiusVariant: {
      type: String,
      default: 'primary',
      validate: (val) => ['primary', 'warning'].indexOf(val) > -1
    },
    disableScrollZoom: {
      type: Boolean,
      default: false
    },
    pointsOfInterest: {
      type: Array,
      default: () => { return [] }
    },
    zoom: {
      type: Number,
      default: 11
    },
    geoShapes: {
      type: Array
    },
    advertisingChannelId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      map: '',
      mapId: Math.round(Math.random() * 1000),
      google: null,
      bounds: {},
      polys: [],
      dragStartData: {},
      dragEndData: {},
      circles: [],
      mapData: {
        response: null,
        location: null
      },
      includedRadiusCircles: [],
      excludedRadiusCircles: [],
      dontRedraw: false,
      addressMarker: null,
      poiMarkers: [],
      maxGeos: 1000
    }
  },
  mounted () {
    this.initializeMap()
  },
  computed: {
    isDDCRTB () {
      return [11, 14, 15, 16].includes(this.advertisingChannelId)
    },
    geoIds () {
      let geoIds = []
      if (!this.isDDCRTB) {
        geoIds = geoIds.concat((this.locations?.excludedGeoCodeTargets || []).map(geo => geo.googleGeoId))
      }
      geoIds = geoIds.concat((this.locations?.includedGeoCodeTargets || []).map(geo => geo.googleGeoId))
      // geoIds = geoIds.concat(this.locations.includedRadiusTargets.map(geo => geo.googleGeoId))
      return geoIds
    },
    locationsToMap () {
      return (this.locations?.includedGeoCodeTargets || [])
        .slice(0, Math.min(this.maxGeos, this.locations?.includedGeoCodeTargets?.length || 0))
        .map(g => g.googleGeoId)
    },
    includedGeos () {
      return (this.locations?.includedGeoCodeTargets || [])
        .slice(0, Math.min(this.maxGeos, this.locations?.includedGeoCodeTargets?.length || 0))
        .map(geo => geo.googleGeoId)
    },
    includedRadius () {
      return (this.locations?.includedRadiusTargets || [])
        .slice(0, Math.min(this.maxGeos, this.locations?.includedRadiusTargets?.length || 0))
    },
    excludedRadius () {
      return (this.locations?.excludedRadiusTargets || [])
        .slice(0, Math.min(this.maxGeos, this.locations?.excludedRadiusTargets?.length || 0))
    },
    excludedGeos () {
      if (this.isDDCRTB) {
        return []
      }
      return (this.locations?.excludedGeoCodeTargets || [])
        .slice(0, Math.min(this.maxGeos, this.locations?.excludedGeoCodeTargets?.length || 0))
        .map(geo => geo.googleGeoId)
    },

    includedRadiusColor () {
      switch (this.includedRadiusVariant) {
        case 'warning':
          return '#FECEA8'
        case 'primary':
        default:
          return '#4286f4'
      }
    }
  },
  watch: {
    locations (newVal) {
      this.initializeMap()
    },
    hidePin (newVal) {
      if (this.addressMarker) {
        this.addressMarker.setMap((newVal) ? null : this.map)
      } else {
        this.initializeMap()
      }
    },
    draggableRadius () {
      this.updateExistingRadiusToBeDraggable()
    },
    updateRadius () {
      this.alterExistingRadius(this.updateRadius.radiusIndex, this.updateRadius.radiusType, this.updateRadius.radius, this.updateRadius)
    }
  },
  methods: {
    updateExistingRadiusToBeDraggable () {
      this.includedRadiusCircles.forEach(circle => {
        circle.setDraggable(this.draggableRadius)
        circle.setEditable(this.draggableRadius)
      })
      this.excludedRadiusCircles.forEach(circle => {
        circle.setDraggable(this.draggableRadius)
        circle.setEditable(this.draggableRadius)
      })
    },
    alterExistingRadius (index, type, radius, radiusChanges) {
      if (!this[type][index]) { // must have an index and a type
        return
      }
      const unitToMeters = (this[type]?.[index]?.radiusUnit === 'KILOMETERS') ? 1000 : 1609.34
      this[type][index].setRadius(radius * unitToMeters)
      if (radiusChanges.itemCentroidLat) {
        this[type][index].setCenter({ lat: radiusChanges.itemCentroidLat, lng: radiusChanges.itemCentroidLng })
      }
    },
    convertRange (value, r1, r2) {
      // rescale 0 - 0.5 and anything outside of 2.5 to max of 2.5
      const val = (value > r1[1]) ? r1[1] : ((value < r1[0]) ? r1[0] : value)

      const a = val - r1[0]
      const b = r2[1] - r2[0]
      let c = r1[1] - r1[0]

      if (c === 0) {
        c = 0.05
      }

      return ((a * b) / c) + r2[0]
    },
    findMissingPolygons () { // if we are missing polygons, figure out which and emit it
      if (this.mapData?.response && this.mapData?.response?.length !== this.geoIds.length && this.mapData.response !== false) {
        const responseGeoIds = this.mapData.response.map(response => response.googleGeoId)
        const missingPolygons = []
        this.geoIds.forEach(geo => {
          if (!responseGeoIds.includes(geo)) {
            missingPolygons.push(geo)
          }
        })
        if (missingPolygons.length > 0) {
          this.$emit('missingPolygons', missingPolygons)
        }
      }
    },
    initializeMap () {
      if (this.dontRedraw) {
        this.dontRedraw = false
        return
      }
      this.loadMap()
    },
    loadMap: _throttle(async function () {
      if (this.account) {
        let response = []
        if (this.geoShapes) {
          response = this.geoShapes.slice(0, Math.min(this.maxGeos, this.geoShapes.length))
        } else if (this.geoIds.length > 0) {
          response = await this.$res.fetch.geoByGoogleIds(this.geoIds.slice(0, Math.min(this.maxGeos, this.geoIds.length)))
        }
        this.mapData.response = response
        this.findMissingPolygons() // find any missing polygons
        if (!this.mapData.location) {
          this.mapData.location = await this.placeMarker()
        }
        if (this.mapData.location && this.$refs.googleMapDiv) {
          this.drawShapes(this.mapData.response, { lat: parseFloat(this.mapData.location.lat), lng: parseFloat(this.mapData.location.lng) })
        }
      }
    }, 5000, { leading: true }),
    addPointsOfInterest (google, map) {
      const svgMarker = {
        path: 'M44.5,15c0-8.271-6.729-15-15-15s-15,6.729-15,15c0,7.934,6.195,14.431,14,14.949V58c0,0.553,0.448,1,1,1s1-0.447,1-1 V29.949C38.305,29.431,44.5,22.934,44.5,15z M24.5,15c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S26.706,15,24.5,15z',
        viewBox: '0 0 59 59',
        fillColor: '#394B63',
        fillOpacity: 1.0,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.5,
        anchor: new google.maps.Point(15, 30)
      }

      this.pointsOfInterest.forEach((poi, poiIndex) => {
        if (poi?.lat !== undefined && poi?.lng !== undefined) {
          const mapPosition = new google.maps.LatLng(poi.lat, poi.lng)

          const marker = new google.maps.Marker({
            position: mapPosition,
            map: (this.hidePin) ? null : map,
            title: poi?.title || null,
            icon: svgMarker
          })

          this.bounds.extend(marker.position)
          this.poiMarkers[poiIndex] = marker
        }
      })
    },
    async drawShapes (shapes, center) {
      const thisInstance = this
      const google = window?.google?.maps ? window.google : await loader.load()

      const mapPosition = new google.maps.LatLng(center.lat, center.lng)
      const mapOptions = {
        zoom: this.zoom,
        center: new google.maps.LatLng(center.lat, center.lng),
        mapTypeId: 'terrain',
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: !this.disableScrollZoom
      }
      const zipCodes = this.includedGeos
      const excludedZips = this.excludedGeos
      const radius = this.includedRadius
      const excludedRadius = this.excludedRadius
      if (this.map) {
        google.maps.event.clearInstanceListeners(this.map)
        this.polys?.forEach(poly => {
          poly.setMap(null)
          google.maps.event.clearInstanceListeners(poly)
        })
        this.circles?.forEach(circ => {
          circ.setMap(null)
          google.maps.event.clearInstanceListeners(circ)
        })
      }
      if (!this.$refs.googleMapDiv) {
        return
      }
      const map = new google.maps.Map(this.$refs.googleMapDiv, mapOptions)
      this.map = map
      this.google = google
      map.addListener('click', this.mapClick)
      map.addListener('zoom_changed', () => {
        const zoomLevel = map.getZoom()
        thisInstance.$emit('zoom-change', zoomLevel)
      })
      // place marker
      this.bounds = new google.maps.LatLngBounds()
      if (center.lat) {
        this.addressMarker = new google.maps.Marker({
          position: mapPosition,
          map: (this.hidePin) ? null : map,
          title: this.account?.name || null
        })
        this.bounds.extend(this.addressMarker.position)
      }
      // end place marker
      // Add Points of Interest
      this.addPointsOfInterest(google, map)
      const polys = []
      if (shapes) {
        const bidMods = []
        for (let i = 0; i < Math.min(this.maxGeos, shapes.length); i++) {
          if (zipCodes.includes(shapes[i].googleGeoId)) {
            const tmp = this.parsePolyStrings(shapes[i].shape, true)
            if (tmp.length && this.locationsToMap[shapes[i].googleGeoId]?.bidModifier) {
              bidMods.push(this.locationsToMap[shapes[i].googleGeoId].bidModifier)
            }
          }
        }
        for (let i = 0; i < Math.min(this.maxGeos, shapes.length); i++) {
          if (zipCodes.includes(shapes[i].googleGeoId)) {
            const tmp = this.parsePolyStrings(shapes[i].shape)
            if (tmp.length) {
              let bidModifier = 0
              let fillOpacity = 0.35
              // fixed scaling here for bid modifier.  bid modifier
              if (this.locationsToMap[shapes[i].googleGeoId]?.bidModifier &&
                this.locationsToMap[shapes[i].googleGeoId].bidModifier !== 1) {
                bidModifier = this.locationsToMap[shapes[i].googleGeoId].bidModifier
                fillOpacity = this.convertRange(bidModifier, [0.5, 2.5], [0.05, 0.8])
              }
              polys[i] = new google.maps.Polygon({
                paths: tmp,
                strokeColor: '#4286f4',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#4286f4',
                fillOpacity,
                shapeObj: shapes[i]
              })
              polys[i].setMap(map)
              polys[i].addListener('click', this.mapClick)

              polys[i].addListener('mouseover', (obj) => {
                let locationName = this.locationsToMap[polys[i].shapeObj.googleGeoId]?.googleCanonicalName
                if (locationName) { // handle a case where there is not a canonical name
                  locationName = locationName.replace(/,/g, ', ')
                } else {
                  locationName = ''
                }
                polys[i].getMap().getDiv().setAttribute('title', locationName)
              })
              polys[i].addListener('mouseout', (obj) => {
                polys[i].getMap().getDiv().removeAttribute('title')
              })
            }
          } else if (excludedZips.includes(shapes[i].googleGeoId)) {
            const tmp = this.parsePolyStrings(shapes[i].shape, true)
            if (tmp.length) {
              polys[i] = new google.maps.Polygon({
                paths: tmp,
                strokeColor: '#FF847C',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#ff847c',
                fillOpacity: 0.35,
                radiusIndex: i,
                radiusType: 'excludedRadiusTargets'
              })
              polys[i].setMap(map)
            }
          }
        }
        this.polys = polys
      }
      if (radius && radius.length > 0) {
        const circles = this.includedRadiusCircles
        const bidMods = []
        for (let i = 0; i < radius.length; i++) {
          if (this.radius[i].bidModifier) {
            bidMods.push(this.radius[i].bidModifier)
          }
        }
        const bidModMin = (_min(bidMods) === 0) ? 0 : _min(bidMods) || 0.5
        const bidModMax = (_max(bidMods) === 0) ? 0 : _max(bidMods) || 2.5
        for (let i = 0; i < Math.min(this.maxGeos, radius.length); i++) {
          const unitToMeters = (radius[i].radiusUnit === 'KILOMETERS') ? 1000 : 1609.34
          let fillOpacity = 0.35
          let bidModifier = 0
          if (this.radius[i].bidModifier) {
            bidModifier = this.radius[i].bidModifier
            fillOpacity = this.convertRange(bidModifier, [bidModMin, bidModMax], [0.05, 0.9])
          }
          if (radius[i].itemCentroidLat) {
            circles[i] = new google.maps.Circle({
              strokeColor: this.includedRadiusColor,
              strokeOpacity: 0.9,
              strokeWeight: 2,
              fillColor: this.includedRadiusColor,
              fillOpacity,
              center: { lat: radius[i].itemCentroidLat, lng: radius[i].itemCentroidLng }, // always requires lat / lng
              radius: (radius[i].radius * unitToMeters), // units are in meters
              radiusUnit: radius[i].radiusUnit, // what units are displayed in the app. Does not do anything at google
              draggable: this.draggableRadius,
              editable: this.draggableRadius,
              radiusIndex: i,
              radiusType: 'includedRadiusTargets'
            })
            circles[i].setMap(map)
          } else if (radius[i].address) { // do an address lookup for lat lng if there isn't one
            const latLng = await this.getLatLngForAddress(radius[i].address)
            circles[i] = new google.maps.Circle({
              strokeColor: this.includedRadiusColor,
              strokeOpacity: 0.9,
              strokeWeight: 2,
              fillColor: this.includedRadiusColor,
              fillOpacity,
              center: latLng, // always requires lat / lng
              radius: (radius[i].radius * unitToMeters), // units are in meters
              radiusUnit: radius[i].radiusUnit, // what units are displayed in the app. Does not do anything at google.
              draggable: this.draggableRadius,
              editable: this.draggableRadius,
              radiusIndex: i,
              radiusType: 'includedRadiusTargets'
            })
            circles[i].setMap(map)
          }
          if (circles[i]) {
            this.bounds.union(circles[i].getBounds())
            const tmpFunc = _debounce(() => {
              this.dontRedraw = true
              this.$emit('circles', circles[i])
              this.$emit('select', circles[i])
            }, 1000)
            circles[i].addListener('center_changed', tmpFunc)
            circles[i].addListener('radius_changed', tmpFunc)
            circles[i].addListener('click', () => {
              this.$emit('select', circles[i])
            })
          }
        }
        this.circles = circles
      }
      if (excludedRadius && excludedRadius.length > 0) {
        const circles = this.excludedRadiusCircles

        for (let i = 0; i < Math.min(this.maxGeos, excludedRadius.length); i++) {
          const unitToMeters = (excludedRadius[i].radiusUnit === 'KILOMETERS') ? 1000 : 1609.34
          const fillOpacity = 0.35
          if (excludedRadius[i].itemCentroidLat) {
            circles[i] = new google.maps.Circle({
              strokeColor: '#FF847C',
              strokeOpacity: 0.9,
              strokeWeight: 2,
              fillColor: '#FF847C',
              fillOpacity,
              center: { lat: excludedRadius[i].itemCentroidLat, lng: excludedRadius[i].itemCentroidLng }, // always requires lat / lng
              radius: (excludedRadius[i].radius * unitToMeters), // units are in meters
              radiusUnit: radius[i].radiusUnit, // what units are displayed in the app. Does not do anything at google.
              draggable: this.draggableRadius,
              editable: this.draggableRadius,
              radiusIndex: i,
              radiusType: 'excludedRadiusTargets'
            })
            circles[i].setMap(map)
          } else if (excludedRadius[i].address) { // do an address lookup for lat lng if there isn't one
            const latLng = await this.getLatLngForAddress(excludedRadius[i].address)
            circles[i] = new google.maps.Circle({
              strokeColor: '#FF847C',
              strokeOpacity: 0.9,
              strokeWeight: 2,
              fillColor: '#FF847C',
              fillOpacity,
              center: latLng, // always requires lat / lng
              radius: (excludedRadius[i].radius * unitToMeters), // units are in meters
              radiusUnit: radius[i].radiusUnit, // what units are displayed in the app. Does not do anything at google.
              draggable: this.draggableRadius,
              editable: this.draggableRadius,
              radiusIndex: i,
              radiusType: 'excludedRadiusTargets'
            })
            circles[i].setMap(map)
          }
          if (circles[i]) {
            this.bounds.union(circles[i].getBounds())
            const tmpFunc = _debounce(() => {
              this.dontRedraw = true
              this.$emit('circles', circles[i])
              this.$emit('select', circles[i])
            }, 1000)
            circles[i].addListener('center_changed', tmpFunc)
            circles[i].addListener('radius_changed', tmpFunc)
            circles[i].addListener('click', () => {
              this.$emit('select', circles[i])
            })
          }
        }
        this.circles = circles
      }
      if (zipCodes.length > 0 || excludedZips.length > 0 || radius.length > 0) {
        map.fitBounds(this.bounds)
      }
      this.map = map
    },

    async placeMarker (callback) {
      if (this.account) {
        // We know the lat/lng; use that
        if (this.account?.latitude && this.account?.longitude) {
          return {
            lat: this.account.latitude,
            lng: this.account.longitude
          }
        }

        // We don't know the lat/lng; so look it up by address
        const address2 = (this.account.address2) ? this.account.address2 : ''
        const address = `${this.account.address1} ${address2}, ${this.account.city}, ${this.account.state} ${this.account.postalCode}`

        const response = await this.$res.fetch.geocodeByAddress(encodeURIComponent(address))

        if (response) {
          return response.location
        }
      }

      return null
    },
    parsePolyStrings (ps, dontExtendBounds) {
      if (!ps) {
        return false
      }
      let j = 0
      let tmpArr = []
      const arr = []
      // match '(' and ')' plus contents between them which contain anything other than '(' or ')'
      const m = ps.match(/([^()]+)/g)
      if (m !== null) {
        for (let i = 0; i < m.length; i++) {
          // match all numeric strings
          const tmp = m[i].match(/-?\d+\.?\d*/g)
          if (tmp !== null) {
            // convert all the coordinate sets in tmp from strings to Numbers and convert to LatLng objects
            for (j = 0, tmpArr = []; j < tmp.length; j += 2) {
              const lng = Number(tmp[j])
              const lat = Number(tmp[j + 1])
              const point = new this.google.maps.LatLng(lat, lng)
              if (!dontExtendBounds) {
                this.bounds.extend(point)
              }
              tmpArr.push(point)
            }
            arr.push(tmpArr)
          }
        }
      }
      // array of arrays of LatLng objects, or empty array
      return arr
    },
    async getLatLngForAddress (address) {
      const response = this.$res.fetch.geocodeByAddress(encodeURIComponent(address))
      if (response) {
        return {
          lat: parseFloat(response.location.lat),
          lng: parseFloat(response.location.lng)
        }
      }
    },
    async mapClick (event) {
      this.$emit('click', event)
    },
    dragEnd (event) {
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      this.dragEndData = { lat, lng }
      this.$emit('circles', this.circles)
    }
  }
}

// WKT: https://stackoverflow.com/questions/16482303/convert-well-known-text-wkt-from-mysql-to-google-maps-polygons-with-php
</script>

<style scoped>
  .google-map {
    width: auto;
    height: 260px;
    margin: 0 auto;
    background: gray;
    box-shadow: 1px 1px #ccc;
    border: 1px solid #ccc;
  }
</style>
